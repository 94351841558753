<template>
  <div class="height-100">
    <v-main class="height-100">
      <v-container fluid class="height-100 pt-0">
        <v-row class="pl-lg-4 ml-md-4">
          <v-col cols="12">
            <v-toolbar flat>
              <v-icon size="90">$vuetify.icons.osg-logo</v-icon>
              <v-divider class="mx-6 grey lighten-1" vertical></v-divider>
              <span class="primary--text text-subtitle-1">{{ webTitle || $t('login.appTitle') }}</span>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row class="height-75" align="center" justify="center">
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col class="d-flex justify-center py-0">
                <v-alert v-model="alert" border="left" max-width="560" elevation="5" colored-border dense :type="alertType">
                  {{ message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4" lg="4" xl="3" class="pt-1">
                <v-row>
                  <v-col cols="12" class="pt-4 pb-4">
                    <label class="text-h3 secondary--text">{{ $t('login.title') }}</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="loginForm"
                      v-model="valid"
                      lazy-validation>
                      <v-row >
                        <v-col cols="12">
                          <v-text-field
                            v-model="email"
                            id="email"
                            :label="$t('login.email.inputLabel')"
                            :rules="[rules.required, rules.email]"
                            name="email"
                            class="rounded"
                            type="text"
                            hide-details="auto"
                            outlined
                            dense
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col cols="12">
                          <v-text-field
                            v-model="password"
                            id="password"
                            :label="$t('login.password.inputLabel')"
                            :rules="[rules.required]"
                            name="password"
                            dense
                            hide-details="auto"
                            outlined
                            class="rounded"
                            required
                            persistent-hint
                            :append-icon="isPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            :type="isPasswordVisible ? 'text' : 'password'"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0 text-right">
                          <v-btn text small color="primary" class="text-buton px-1" @click="forgotPassword()">{{ $t('login.forgotPasswordButton') }}</v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <div>
                      <v-row no-gutters class="d-flex justify-end mt-10">
                        <v-col cols="6" >
                          <v-btn
                            depressed
                            min-width="100%"
                            color="primary"
                            class="text-osg-button mb-4 rounded"
                            @click="validate()"
                          >{{ $t('login.loginButton') }}</v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <OsiguFooter />
  </div>
</template>

<script>
import { inputRules } from '@/utils'
import { createNamespacedHelpers } from 'vuex'
import OsiguFooter from '../footer/OsiguFooter.vue';

const authModule = createNamespacedHelpers('auth');
const authorizationModule = createNamespacedHelpers('authorization');
const agreementsModule = createNamespacedHelpers('agreements');

export default {
  name: 'Login',
  components: {
    OsiguFooter
  },
  data: () => ({
    valid: true,
    alertType: 'error',
    alert: false,
    message: '',
    rules: inputRules,
    email: '',
    password: '',
    isPasswordVisible: false,
  }),
  created() {
    const wasPasswordChanged = this.$store.state.auth.changePasswordStatus;
    if (wasPasswordChanged === 'success') {
      this.alert = true;
      this.alertType = 'success';
      this.message = this.$i18n.t('changePassword.success.label');
      this.resetChangePasswordStatus();
    }

    if (this.agreementError.message) {
      this.alert = true;
      this.alertType = this.agreementError.type;
      this.message = this.$i18n.t(this.agreementError.message);
    }

  },
  computed: {
    ...authModule.mapGetters(['authStatus', 'webTitle']),
    ...agreementsModule.mapGetters(['agreementError', 'hasPendingDocumentsToAgree'])
  },
  methods: {
    ...authModule.mapActions(['requestLogin', 'resetChangePasswordStatus', 'setEmail']),
    ...authorizationModule.mapActions(['getBeneficiary']),
    ...agreementsModule.mapActions(['validateAgreementDocuments', 'setPendingAgreementDocuments', 'resetError']),
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.login()
      }
    },
    async login() {
      this.$refs.loginForm.validate()
      this.resetError()
      this.requestLogin({ username: this.email, password: this.password })
      .then(async () => {
        this.alertType = 'error';
        this.alert = false

        const tokenExtensions = this.$store.getters['auth/tokenExtensions'];
        const { beneficiariesWebVersion } = tokenExtensions

        if(beneficiariesWebVersion === 'v2'){
          this.$router.push('/v2/home')
          return
        }

        await this.getBeneficiary();
        this.setPendingAgreementDocuments([])
        await this.validateAgreementDocuments();
         if (this.hasPendingDocumentsToAgree) {
           this.$router.push('/agreement')
         } else {
           this.$router.push('/home')
         }
      })
      .catch(error => {
        this.alertType = 'error';
        this.message = this.$i18n.t('globalErrorUnknown')
        if (error.response) {
          const status = error.response.status
          if (status >= 400 && status < 500) {
            this.message = this.$i18n.t('badCredentials')
          }
        }
        this.alert = true
      })
    },
    forgotPassword: function () {
      this.resetError()
      if (this.email) {
        this.setEmail(this.email)
      }
      this.$router.push('/forgot-password')
    },
  }
}
</script>

<style lang="scss" scoped>
.height-100 {
  height: 100%;
}

.height-75 {
  height: 75%;
}
</style>
